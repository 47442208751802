export default [
  {
    path: "/app-setting",
    name: "app-setting",
    component: () => import("@/modules/settings/app-setting/AppSetting.vue"),
    children: [
      {
        path: "list",
        name: "list-app-settings",
        component: () =>
          import("@/modules/settings/app-setting/pages/AppSettingList.vue"),
        meta: { permissions: ["app-settings-show"] }
      }
    ]
  }
];
