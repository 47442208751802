<template>
  <button @click="goBack" class="btn btn-light-primary font-weight-bolder mr-2">
    <i class="ki ki-long-arrow-back icon-xs"></i>{{ $t("BUTTON.BACK") }}
  </button>
</template>
<script>
export default {
  name: "BackButton",
  inheritAttrs: false,
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
