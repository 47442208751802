import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_APP_SETTINGS = "fetchAppSettings";
export const UPDATE_APP_SETTINGS = "updateAppSettings";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingAppSetting";
export const SET_APP_SETTINGS = "setAppSettings";

const state = {
  errors: [],
  isLoadingAppSetting: false
};

const getters = {
  isLoadingAppSetting() {
    return state.isLoadingAppSetting;
  }
};

const actions = {
  [FETCH_APP_SETTINGS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("app-settings" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_APP_SETTINGS](context, params) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("app-settings-update-many", params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingAppSetting = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingAppSetting = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
