import Vue from "vue";
import moment from "moment";

Vue.prototype.$datetime = {
  getTimeRanges(interval, start = 0, end = 24) {
    const ranges = [];
    const date = new Date();

    for (
      let minutes = start * 60;
      minutes < end * 60;
      minutes = minutes + interval
    ) {
      date.setHours(0);
      date.setMinutes(minutes);
      ranges.push(moment(date).format("HH:mm"));
    }

    return ranges;
  }
};
