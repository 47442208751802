import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const EMAIL = "email";
export const TWOFACTOR = "twoFactor";
export const FETCH_CURRENT_USER = "fetchCurrentUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_USER_DATA = "setUserData";
export const UPDATE_USER_DATA = "updateUserData";

const state = {
  errors: [],
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_ERROR, []);
          if (data) {
            context.commit(SET_AUTH, data);
            resolve(data);
          }
        })
        .catch(({ response }) => {
          if (response.status === 400) {
            response.data.errors.info = response.data.error;
          }
          context.commit(SET_ERROR, response?.data?.message);
          reject(response);
        });
    });
  },
  [FETCH_CURRENT_USER](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("current-user")
        .then(({ data }) => {
          context.commit(SET_ERROR, []);
          context.commit(SET_USER_DATA, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.status === 400) {
            response.data.errors.info = response.data.error;
          }
          context.commit(SET_ERROR, response?.data?.message);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("logout")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response?.data?.errors?.info);
          reject();
        })
        .finally(() => {
          context.commit(PURGE_AUTH);
        });
    });
  },
  [TWOFACTOR](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("two-factor/send", payload)
        .then(() => {
          context.commit(SET_ERROR, []);
          resolve();
        })
        .catch(({ response }) => {
          // console.log(response);
          if (response?.data?.status === 404) {
            response.data.errors.info = response.data.error;
          }
          context.commit(
            SET_ERROR,
            response?.data?.errors?.info || "Възникна грешка!"
          );
          reject(response);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data.data.userData;
    state.errors = [];
    JwtService.saveTokens(data.data);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = [];
    JwtService.destroyTokens();
  },
  [UPDATE_USER_DATA](state, data) {
    state.user = data;
    JwtService.updateUserData(data);
  },
  [SET_USER_DATA](state, data) {
    state.user = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
