import Vue from "vue";
import moment from "moment";
import fileService from "@/core/services/file.service.js";
import avatarService from "@/core/services/avatar.service.js";
import prettyBytes from "pretty-bytes";

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("twoLetters", function(value) {
  return typeof value !== "undefined" ? value.charAt(0) + value.charAt(1) : "";
});

Vue.filter("getInitials", function(value) {
  return avatarService.getInitials(value);
});

Vue.filter("getInitialsColor", function(value) {
  return avatarService.getInitialsColor(value);
});

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY");
  }
});

Vue.filter("formatDateTime", function(value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY HH:mm:ss");
  }
});

Vue.filter("fileIcon", function(value) {
  if (value) {
    return fileService.getFileIcon(value);
  }
});

Vue.filter("formatBalance", function(value) {
  let val = (value / 1).toFixed(2).replace(",", ".");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
});

Vue.filter("prettyBytes", function(value) {
  return prettyBytes(value);
});
