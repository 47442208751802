import ApiService from "@/core/services/api.service";

//Actions
export const FETCH_STORES_BASIC = "fetchStoresBasic";
export const FETCH_STORES_WITH_PROVIDERS = "fetchStoresWithProviders";

//Mutations
export const SET_LOADING = "setLoadingSharedStore";

const state = {
  isLoadingSharedStore: false
};

const getters = {
  isLoadingSharedStore() {
    return state.isLoadingSharedStore;
  }
};

const actions = {
  [FETCH_STORES_BASIC](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stores-basic" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STORES_WITH_PROVIDERS](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stores-with-providers" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_LOADING](state, value) {
    state.isLoadingSharedStore = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
