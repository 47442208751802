<template>
  <button
    @click="$emit('onDelete')"
    class="btn btn-light-danger font-weight-bolder mr-2"
  >
    <i class="flaticon-delete"></i>{{ $t("BUTTON.DELETE") }}
  </button>
</template>
<script>
export default {
  name: "BackButton",
  inheritAttrs: false,
  data() {
    return {};
  },
  methods: {}
};
</script>
