export default [
  {
    path: "/shop",
    name: "shop",
    component: () => import("@/modules/shop/Shop.vue"),
    children: [
      {
        path: "list",
        name: "list-shops",
        component: () => import("@/modules/shop/pages/ShopList.vue"),
        meta: { permissions: ["shops-show"] }
      },
      {
        path: "add",
        name: "add-shop",
        component: () => import("@/modules/shop/pages/ShopAdd.vue"),
        meta: { permissions: ["shops-store"] }
      },
      {
        path: "edit/:id",
        name: "edit-shop",
        component: () => import("@/modules/shop/pages/ShopEdit.vue"),
        meta: { permissions: ["shops-update"] }
      }
    ]
  }
];
