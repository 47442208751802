import i18nService from "@/core/services/i18n.service.js";

//Actions

//Mutations
export const SET_TAB_LANGUAGE = "setTabLanguage";

const state = {
  languages: i18nService.languages,
  tabLanguage: i18nService.languages[0]
};

const getters = {
  getLanguages() {
    return state.languages;
  },
  getTabLanguage() {
    return state.tabLanguage;
  }
};

const actions = {};

const mutations = {
  [SET_TAB_LANGUAGE](state, lang) {
    state.tabLanguage = lang;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
