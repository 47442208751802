const ACCESS_TOKEN_KEY = "access_token";
const USER = "userData";

export const getToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem(USER));
};

export const saveTokens = token => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, token.token);
  window.localStorage.setItem(USER, JSON.stringify(token.userData));
};

export const updateUserData = userData => {
  window.localStorage.setItem(USER, JSON.stringify(userData));
};

export const destroyTokens = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.localStorage.removeItem(USER);
};

export default {
  getToken,
  saveTokens,
  updateUserData,
  destroyTokens,
  getUser
};
