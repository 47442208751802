import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_PRODUCTS = "fetchProducts";

//Mutations

const state = {
  errors: []
};

const getters = {};

const actions = {
  [FETCH_PRODUCTS](context, apiParams = "") {
    return new Promise((resolve, reject) => {
      ApiService.get("products" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};
