import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_ROLES = "fetchRoles";
export const FETCH_ROLE = "fetchRole";
export const SAVE_ROLE = "saveRole";
export const UPDATE_ROLE = "updateRole";
export const DELETE_ROLE = "deleteRole";
export const FETCH_PERMISSIONS = "fetchPermissions";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingRole";
export const SET_ROLES = "setRoles";

const state = {
  errors: [],
  roles: [],
  isLoadingRole: false
};

const getters = {
  isLoadingRole() {
    return state.isLoadingRole;
  },
  getRoles() {
    return state.roles;
  }
};

const actions = {
  [FETCH_ROLES](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    const paramsWithRole =
      apiParams + (apiParams ? "&onlyAdmins=1" : "?onlyAdmins=1");

    return new Promise((resolve, reject) => {
      ApiService.get("roles" + paramsWithRole)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ROLE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("roles", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_ROLE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("roles", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_ROLE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("roles", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_ROLE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("roles", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_PERMISSIONS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("permissions" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingRole = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingRole = value;
  },
  [SET_ROLES](state, data) {
    state.isLoadingRole = false;
    state.roles = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
