export default [
  {
    path: "/promotion",
    name: "promotion",
    component: () => import("@/modules/promotion/Promotion.vue"),
    children: [
      {
        path: "list",
        name: "list-promotions",
        component: () => import("@/modules/promotion/pages/PromotionList.vue"),
        meta: { permissions: ["promotions-show"] }
      },
      {
        path: "add",
        name: "add-promotion",
        component: () => import("@/modules/promotion/pages/PromotionAdd.vue"),
        meta: { permissions: ["promotions-store"] }
      },
      {
        path: "edit/:id",
        name: "edit-promotion",
        component: () => import("@/modules/promotion/pages/PromotionEdit.vue"),
        meta: { permissions: ["promotions-update"] }
      }
    ]
  }
];
