import Vue from "vue";

Vue.prototype.$url = {
  transformParams(params) {
    const apiParams =
      "?" +
      Object.keys(params)
        .map((key) => (params[key] !== null ? key + "=" + params[key] : null))
        .join("&");
    return apiParams;
  },
};
