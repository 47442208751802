export const PROJECT_NAME = process.env.VUE_APP_PROJECT_NAME;
export const BASE_API_URL = process.env.VUE_APP_BASE_API_URL;
export const AUTH_CLIENT_ID = process.env.VUE_APP_AUTH_CLIENT_ID;
export const AUTH_CLIENT_SECRET = process.env.VUE_APP_AUTH_CLIENT_SECRET;
export const API_URL = BASE_API_URL;
export const GUARD_NAME = {
  ADMIN: "admin",
  USER: "user",
  DRIVER: "driver"
};
export default API_URL;
