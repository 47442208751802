import ApiService from "@/core/services/api.service";

//Actions
export const FETCH_NO_BOL = "fetchNoBol";

const state = {};

const getters = {};

const actions = {
  [FETCH_NO_BOL](context, apiParams = "") {
    return new Promise((resolve, reject) => {
      ApiService.get("gps-log/no-bol" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
