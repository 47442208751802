import ApiService from "@/core/services/api.service";

//Actions
export const FETCH_LOCATIONS_BASIC = "fetchLocationsBasic";

//Mutations
export const SET_LOADING = "setLoadingSharedLocation";

const state = {
  isLoadingSharedLocation: false
};

const getters = {
  isLoadingSharedLocation() {
    return state.isLoadingSharedLocation;
  }
};

const actions = {
  [FETCH_LOCATIONS_BASIC](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("locations-basic" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_LOADING](state, value) {
    state.isLoadingSharedLocation = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
