import Vue from "vue";
import Router from "vue-router";

// Accounts
import UserRouter from "@/modules/accounts/user/router.js";
import AdminRouter from "@/modules/accounts/admin/router.js";
import RoleRouter from "@/modules/accounts/role/router.js";

// Settings
import AppSettingRouter from "@/modules/settings/app-setting/router.js";

import PromotionRouter from "@/modules/promotion/router.js";
import ShopRouter from "@/modules/shop/router.js";

import { authGuard } from "@/guard.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        ...UserRouter,
        ...AdminRouter,
        ...RoleRouter,
        ...AppSettingRouter,
        ...PromotionRouter,
        ...ShopRouter
      ],
      meta: { requiresAuth: true }
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        }
      ],
      meta: { requiresAuth: false }
    },
    {
      path: "*",
      redirect: "/404",
      meta: { requiresAuth: false }
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => {
        return import("@/view/pages/error/Error-4.vue");
      },
      meta: { requiresAuth: false }
    }
  ]
});

router.beforeEach(authGuard);
export default router;
