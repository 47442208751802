import Vue from "vue";
import Vuex from "vuex";

// Core
import shared from "./shared.js";

// Core Custom
import lang from "./lang.module";

// Accounts
import user from "@/modules/accounts/user/store/user.module";
import role from "@/modules/accounts/role/store/role.module";

// Settings
import appSetting from "@/modules/settings/app-setting/store/app-setting.module";

import promotion from "@/modules/promotion/store/promotion.module";
import shop from "@/modules/shop/store/shop.module";
import category from "@/modules/category/store/category.module";
import product from "@/modules/product/store/product.module";
import productPromotion from "@/modules/product-promotion/store/product-promotion.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ...shared,
    lang,
    user,
    role,
    appSetting,
    promotion,
    shop,
    category,
    product,
    productPromotion
  }
});
