// BG
export const locale = {
  GENERAL: {
    APP_TITLE: "PromoHint"
  },
  NOTIFY: {
    DEFAULT_ERROR: "Възникна грешка"
  },
  TRANSLATOR: {
    SELECT: "Избери своя език"
  },
  MENU: {
    SECTION: {
      SETTINGS: "Настройки"
    },
    ITEM: {
      DASHBOARD: "Начало",
      LIST: "Списък",
      ADD: "Добавяне",
      EDIT: "Редактиране",
      ACTIVE: "Активен",
      STATUS: "Статус",
      ACTIONS: "Опции",
      ROLES: {
        ROLES: "Роли"
      },
      USERS: {
        USERS: "Потребители"
      },
      SHOPS: {
        SHOPS: "Магазини"
      },
      PROMOTIONS: {
        PROMOTIONS: "Промоции"
      },
      SETTINGS: {
        SETTINGS: "Настройки",
        APP_SETTINGS: "Основни",
        CITIES: "Градове"
      },
      ACCOUNTS: {
        ACCOUNTS: "Акаунти",
        USERS: "Потребители",
        ADMINS: "Администратори",
        ROLES: "Роли",
        USER_ADDRESSES: "Адреси"
      }
    }
  },
  BUTTON: {
    NEW: "Нов",
    SEND: "Изпрати",
    READY: "Готово",
    BACK: "Назад",
    ADD: "Добави",
    ADD_NEW: "Добави нов",
    DELETE: "Изтрий",
    SAVE: "Запази",
    COPY: "Копирай",
    DUPLICATE: "Дублирай",
    SAVE_AND_CONTINUE: "Запази и продължи",
    SAVE_AND_COPY: "Запази и копирай",
    SAVE_AND_ADD_NEW: "Запази и добави нов",
    SAVE_AND_EXIT: "Запази и излез",
    SET_NEW_PASSWORD: "Промени Паролата",
    NO_NEW_PASSWORD: "Не Променяй Паролата",
    GENERATE_PASSWORD: "Генерирай Парола",
    GENERATE: "Генерирай",
    CONTINUE: "Продължи",
    UPLOAD: "Качи",
    REFRESH: "Презареди",
    FILES: "Файлове",
    DOWNLOAD: "Свали",
    CLOSE: "Затвори",
    CONFIRM: "Потвърди",
    ALL: "Всички",
    APPLY: "Приложи",
    APPLY_FOR_ALL_STORES: "Приложи за всички магазини",
    CREATE: "Създай",
    PLEASE_CONFIRM: "Моля потвърдете",
    ACTIONS: "Действия",
    FINALIZE: "Финализирай",
    PREVIEW: "Преглед",
    PRINT: "Разпечатай",
    EXCEL: "Ексел",
    PDF: "PDF",
    PDFPRINT: "PDF/PRINT",
    TEST_ERRORS: "Тест грешки"
  },
  TABLE: {
    ITEMS_PER_PAGE_TEXT: "Резултати на страница"
  },
  AUTH: {
    GENERAL: {
      FOOTER_LEFT: "Булмаг",
      WELCOME: "Булмаг",
      WELCOME_DESCRIPTION: "Платформа за управление",
      SIGNUP_BUTTON: "Вход",
      BACK_BUTTON: "Назад",
      PRIVACY: "Политика за поверителност"
    },
    LOGIN: {
      TITLE: "Вход",
      DESCRIPTION:
        "Моля свържете се с администратор за да получите потребителско име и парола",
      TWO_FACTOR: "Моля въведете четирицифрения код, изпратен на вашия имейл.",
      VALID_EMAIL: "Въведете валиден имейл.",
      VALID_PASSWORD: "Паролата е задължителна.",
      VALID_PASSWORD_CONFIRM: "Паролите не съвпадат.",
      BUTTON: "Вход",
      PRIVACY: "",
      LEGAL: "",
      CONTACT: ""
    },
    TWO_FACTOR: {
      TITLE: "2FA",
      DESCRIPTION:
        "За да продължите, въведете 4 цифрения код изпратен на вашия имейл!",
      CODE: "Код",
      VALID_CODE: "Въведете валиден код."
    },
    FORGOT: {
      TITLE: "Забравена парола?",
      SUCCESS: "Ще получите имейл с линк за рестартиране на паролата си.",
      DESC: "Въведете вашия имейл, за да рестартирате паролата си!"
    },
    RESET: {
      TITLE: "Въведете вашата нова парола",
      DESC: "Въведете вашия имейл, за да рестартирате паролата си!",
      SUCCESS: "Вашата парола беше успешно рестартирана."
    },
    REGISTER: {
      TITLE: "Регистрация",
      DESC: "Въведете вашите данни, за да създадете акаунт",
      SUCCESS: "Вашият акаунт е създаден успешно."
    },
    INPUT: {
      EMAIL: "Имейл",
      FULLNAME: "Имена",
      PASSWORD: "Парола",
      CONFIRM_PASSWORD: "Потвърдете парола",
      USERNAME: "Потребителско име"
    }
  },
  VALIDATION: {
    VALID: "",
    INVALID: "Полето {name} е невалидно",
    REQUIRED: "Полето {name} е задължително",
    LENGTH: "Полето {name} трябва да е {length} символа",
    MIN_LENGTH: "Полето {name} трябва да е минимум {min} символа",
    AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
    NOT_FOUND: "The requested {name} is not found",
    INVALID_LOGIN: "The login detail is incorrect",
    REQUIRED_FIELD: "Required field",
    MIN_LENGTH_FIELD: "Minimum field length:",
    MAX_LENGTH_FIELD: "Maximum field length:",
    INVALID_FIELD: "Field is not valid",
    INVALID_FILE: "Файла не е валиден",
    NO_RESULTS: "Няма намерени резултати.",
    FILE_BIG_SIZE: "Файла е твърде голям. Позволена големина: {value}",
    FILE_TYPE: "Непозволен тип на файл. Позволени типове: {value}"
  },
  PROFILE: {
    TITLE: "Потребителски Профил",
    HI: "Здравей",
    LOGOUT: "Изход",
    MY_PROFILE: "Моя профил",
    PROFILE_SETTINGS: "Настройки на профила"
  },
  FORM: {
    VALID_EMAIL: "Въведете валиден имейл.",
    VALID_NAME: "Полето е невалидно.",
    VALID_TYPE: "Въведете валиден тип.",
    VALID_FIRST_NAME: "Въведете валидно име.",
    VALID_LAST_NAME: "Въведете валиднa фамилия.",
    VALID_ACTIVE: "Въведете валиднa стойност за активен",
    VALID_ROLE: "Въведете валиднa роля",
    VALID_PHONE: "Телефоният номер трябва да е точно 10 цифри и да започва с 0"
  },
  FORM_LABELS: {
    ROLE_TYPE: "Тип",
    GROUP: "Група",
    EMAIL: "Email адрес",
    NAME: "Име",
    FIRST_NAME: "Име",
    LAST_NAME: "Фамилия",
    ACTIVE: "Активен",
    ROLE: "Роля",
    ROLES: "Роли",
    PERMISSIONS: "Права",
    LOCATION: "Местоположение",
    ADDRESS: "Адрес",
    DESCRIPTION: "Описание",
    NOTES: "Бележки",
    SEARCH_PLACE: "Търси място",
    FROM: "От",
    TO: "До",
    SLUG: "Slug",
    PASSWORD: "Парола",
    SELECT_ALL: "Избери всички",
    NO_SELECTED: "Няма избрани",
    SELECTED: "Избрани",
    ALL_SELECTED: "Всички са избрани",
    DEFAULT_COST: "Стойност",
    PRICE: "Цена",
    DIFFERENT_PRICE: "Различна цена?",
    UNIT_VALUE: "Мерна единица стойност",
    UNIT: "Мерна единица",
    SHORTCODE: "Съкращение",
    SORT: "Подредба",
    TITLE: "Заглавие",
    ICON: "Иконка",
    IMG: "Снимка",
    VALID_RANGE: "Дата от - до",
    VALUE: "Стойност",
    VALUE_NO_VAT: "Стойност без ДДС",
    VALUE_WITH_VAT: "Стойност с ДДС",
    VALUE_VAT: "Стойност  ДДС",
    VALUE_FROM_PROTOCOLS: "Стойност протоколи",
    VAT: "ДДС",
    USERS: "Потребители",
    USER_GROUPS: "Потребителски Групи",
    CUSTOMERS: "Клиенти",
    CUSTOMER_GROUPS: "Клиентски Групи",
    NUMBER_OF_CODES: "Брой кодове",
    CREATED_AT: "Създаден на",
    UPDATE_AT: "Обновен на",
    IMAGE: "Снимка",
    PHONE: "Телефон",
    TYPE: "Тип",
    COMPANY: "Име на компания",
    COMPANY_CITY: "Населено място",
    COMPANY_ADDRESS: "Адрес на компанията",
    COMPANY_PHONE: "Телефон на компанията",
    BULSTAT: "Номер по ДДС",
    VAT: "ЕИК",
    MOL: "МОЛ",
    USER_TYPE_INDIVIDUAL: "Физическо лице",
    USER_TYPE_COMPANY: "Юридическо лице",
    COMMENT: "Коментар",
    IS_DEFAULT: "Основен",
    IS_NEW: "Нов",
    LAT: "LAT",
    LNG: "LNG",
    URL: "Линк",
    CATEGORIES: "Категории",
    SHOP: "Магазини",
    PROMO_CODE: "Промо код",
    PROMO_PRICE: "Промо цена",
    UPDATE_PRODUCT: "Промени продукт",
    SELECT_PRODUCT: "Избери продукт или добави нов",
    PRODUCT: "Продукт"
  },
  FORM_PLACEHOLDERS: {
    EMAIL: "Email адрес",
    NAME: "Име",
    FIRST_NAME: "Име",
    LAST_NAME: "Фамилия",
    ACTIVE: "Активен",
    ROLE: "Роля",
    LAT: "Latitude",
    LNG: "Longitude",
    LOCATION: "Местоположение",
    ADDRESS: "Адрес",
    DESCRIPTION: "Описание",
    NOTES: "Бележки",
    CITY: "Град",
    SLUG: "Slug",
    LANG: "Език",
    PASSWORD: "Парола",
    ITEM_CATEGORY_TYPE: "Тип категория",
    SORT: "Подредба",
    TITLE: "Заглавие",
    IMG: "Снимка",
    ICON: "Пример: circle-user",
    URL: "Външен линк"
  },

  LABELS: {
    LIST: "Списък",
    ADDRESS: "Адрес",
    ADD_NEW: "Добавяне",
    EXPORT: "PDF",
    SAVE: "Запазване",
    EDIT: "Редактиране",
    CLEAR: "Изчистване",
    SEARCH: "Търсене",
    ADD_POLYGON: "Добави полигон",
    RESET_POLYGON: "Изчисти полигон",
    RECENTER_MAP: "Центрирай картата",
    STEP: "Стъпка",
    VIEW: "Преглед",
    SHOW_BILLS_OF_LADING: "Покажи товарителници",
    PRINT: "Разпечатай",
    YEAR: "Година",
    WEEK: "Седмица",
    DAY: "Ден"
  },
  WEEK_DAYS: {
    MONDAY: "Понеделник",
    TUESDAY: "Вторник",
    WEDNESDAY: "Сряда",
    THURSDAY: "Четвъртък",
    FRIDAY: "Петък",
    SATURDAY: "Събота",
    SUNDAY: "Неделя",
    DAYS: "ДНИ"
  },
  ALERTS: {
    ERROR: "Грешка",
    NO_DATA: "Няма данни",
    WARNING: "Вниемание",
    BILL_NUMBER_EXIST: "Вече съществува товарителница с този номер!"
  },
  ACTION_STATUS: {
    IS_SET: "Действието предстои да започне.",
    IS_RUNNING: "Действието все още е в процес на извършване.",
    IS_FINISHED: "Действието е извършено успешно.",
    IS_FAILED: "Действието е извършено неуспешно.",
    IS_CANCELLED: "Действието е прекратено.",
    REFRESH_STATUS:
      "За да проследите дали статуса се е сменил, моля презаредете."
  },
  PERMISSIONS: {
    users: "Потребители",
    "users-show": "Потребители - показване",
    "users-store": "Потребители - добавяне",
    "users-update": "Потребители - обновяване",
    "users-destroy": "Потребители - изтриване",
    "user-addresses": "Адреси на потребители",
    "user-addresses-show": "Адреси на потребители - показване",
    roles: "Роли",
    "roles-show": "Роли - показване",
    "roles-store": "Роли - добавяне",
    "roles-update": "Роли - обновяване",
    "roles-destroy": "Роли - изтриване",
    shops: "Магазини",
    "shops-show": "Магазини - показване",
    "shops-store": "Магазини - добавяне",
    "shops-update": "Магазини - обновяване",
    "shops-destroy": "Магазини - изтриване",
    "promotions-show": "Промоции - показване",
    "promotions-store": "Промоции - добавяне",
    "promotions-update": "Промоции - обновяване",
    "promotions-destroy": "Промоции - изтриване",
    "app-settings": "Основни настройки",
    "app-settings-show": "Основни настройки - показване",
    "app-settings-update": "Основни настройки - обновяване"
  },
  ROLES: {
    GUARDS: {
      ADMIN: "Админ",
      USER: "Потребител",
      DRIVER: "Шофьор"
    }
  }
};
