import ApiService from "@/core/services/api.service";

//Actions
export const SAVE_PRODUCT_PROMOTION = "saveProductPromotion";
export const UPDATE_PRODUCT_PROMOTION = "updateProductPromotion";
export const DELETE_PRODUCT_PROMOTION = "deleteProductPromotion";

//Mutations

const state = {
  errors: []
};

const getters = {};

const actions = {
  [SAVE_PRODUCT_PROMOTION](context, payload) {
    const params = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("product-promotion", params, {}, "image")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [UPDATE_PRODUCT_PROMOTION](context, params) {
    return new Promise((resolve, reject) => {
      const form = params.payload;
      const id = params.id;

      ApiService.update(`product-promotion`, id, form, true)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [DELETE_PRODUCT_PROMOTION](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete("product-promotion", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};
