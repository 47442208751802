import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import { API_URL } from "@/core/config/config.js";
import i18nService from "./i18n.service";

/**
 * Set the default HTTP request headers
 */
const setHeader = () => {
  axios.interceptors.request.use(config => {
    let token = JwtService.getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    let language = i18nService.getActiveLanguage();
    if (language) {
      config.headers["Accept-Language"] = `${language}`;
    } else {
      config.headers["Accept-Language"] = `en`;
    }

    return config;
  });
};

const prepareFormData = (params, imageKey) => {
  const image = params[imageKey];

  let newParams = { ...params };
  delete newParams[imageKey];

  let formData = new FormData();
  if (image) {
    formData.append("image", image);
  }
  formData.append("payload", JSON.stringify(newParams));
  return formData;
};

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    setHeader();
  },

  query(resource, params) {
    return Vue.axios.get(resource, {
      params: params
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    let url = "";
    if (slug != "") {
      url = `${resource}/${slug}`;
    } else {
      url = `${resource}`;
    }
    return Vue.axios.get(url);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params = {}, options = {}, imageKey = null) {
    const finalParams = imageKey ? prepareFormData(params, imageKey) : params;
    return Vue.axios.post(`${resource}`, finalParams, options);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params, multipart = false) {
    if (multipart) {
      return Vue.axios.post(`${resource}/${slug}?_method=PUT`, params);
    } else {
      return Vue.axios.put(`${resource}/${slug}`, params);
    }
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, slug) {
    let url = resource;

    if (slug) {
      url += "/" + slug;
    }

    return Vue.axios.delete(url);
  }
};

export default ApiService;
