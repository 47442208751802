import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_PROMOTIONS = "fetchPromotions";
export const FETCH_PROMOTION = "fetchPromotion";
export const SAVE_PROMOTION = "savePromotion";
export const UPDATE_PROMOTION = "updatePromotion";
export const DELETE_PROMOTION = "deletePromotion";
export const SEND_NOTIFICATIONS = "sendNotifications";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingPromotion";

const state = {
  errors: [],
  isLoadingPromotion: false
};

const getters = {
  isLoadingPromotion() {
    return state.isLoadingPromotion;
  },
  getPromotions() {
    return state.promotions;
  }
};

const actions = {
  [FETCH_PROMOTIONS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("promotions" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_PROMOTION](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("promotions", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_PROMOTION](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("promotions", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_PROMOTION](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("promotions", id, form, true)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_PROMOTION](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("promotions", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SEND_NOTIFICATIONS](context, payload) {
    context.commit(SET_LOADING, true);
    const form = {
      id: payload
    };
    return new Promise((resolve, reject) => {
      ApiService.post("promotions-send-notifications", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingPromotion = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingPromotion = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
