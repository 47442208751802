import ApiService from "@/core/services/api.service";

//Actions
export const FETCH_BRANDS_BASIC = "fetchBrandsBasic";
export const FETCH_BRAND_ITEM_CATEGORIES = "fetchBrandItemCategories";

//Mutations
export const SET_LOADING = "setLoadingSharedBrand";

const state = {
  isLoadingSharedBrand: false
};

const getters = {
  isLoadingSharedBrand() {
    return state.isLoadingSharedBrand;
  }
};

const actions = {
  [FETCH_BRANDS_BASIC](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("brands" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_BRAND_ITEM_CATEGORIES](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("brand-item-categories" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_LOADING](state, value) {
    state.isLoadingSharedBrand = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
